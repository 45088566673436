import { useState, useEffect } from 'react';

import { supabase } from '../../supabaseClient';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './Plans.css';


const Plans = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [workouts, setWorkouts] = useState([]);
  const [user, setUser] = useState(null);

  // Get current user
  useEffect(() => {
    const getCurrentUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    getCurrentUser();
  }, []);

  // Fetch workouts for selected date and trainer
  useEffect(() => {
    const fetchWorkouts = async () => {
      if (!user) return;

      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      const { data, error } = await supabase
        .from('workouts')
        .select(`
          *,
          clients (
            first_name,
            last_name,
            email
          ),
          workout_exercises (
            sets,
            reps,
            time,
            weight,
            exercises (
              name,
              muscle_group
            )
          )
        `)
        .eq('trainer_id', user.id)
        .gte('workout_date', startOfDay.toISOString())
        .lte('workout_date', endOfDay.toISOString())
        .order('workout_date', { ascending: true });

      if (error) {
        console.error('Error fetching workouts:', error);
        return;
      }

      setWorkouts(data);
    };

    fetchWorkouts();
  }, [selectedDate, user]);

  return (
    <div className="plans-container">
      <div className="plans-header">
        <h2>Daily Workout Plans</h2>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MMMM d, yyyy"
          className="date-picker"
        />
      </div>

      {workouts.length === 0 ? (
        <div className="no-workouts">
          No workouts planned for this date.
        </div>
      ) : (
        <div className="workouts-grid">
          {workouts.map((workout) => (
            <div key={workout.id} className="workout-plan-card">
              <div className="client-info">
                <h3>{workout.clients.first_name} {workout.clients.last_name}</h3>
                <span className="client-email">{workout.clients.email}</span>
                <span className={`status ${workout.completed ? 'completed' : 'pending'}`}>
                  {workout.completed ? 'Completed' : 'Pending'}
                </span>
              </div>

              <div className="workout-details">
                <div className="details-header">
                  <span className="exercise-col">Exercise</span>
                  <span className="detail-col">Sets</span>
                  <span className="detail-col">Reps</span>
                  <span className="detail-col">Weight</span>
                  <span className="detail-col">Time</span>
                </div>

                {workout.workout_exercises.map((exercise, index) => (
                  <div key={index} className="exercise-row">
                    <div className="exercise-col">
                      <span className="exercise-name">{exercise.exercises.name}</span>
                      <span className="muscle-group">({exercise.exercises.muscle_group})</span>
                    </div>
                    <span className="detail-col">{exercise.sets || '-'}</span>
                    <span className="detail-col">{exercise.reps || '-'}</span>
                    <span className="detail-col">{exercise.weight || '-'}</span>
                    <span className="detail-col">{exercise.time || '-'}</span>
                  </div>
                ))}
              </div>

              {workout.notes && (
                <div className="workout-notes">
                  <h4>Notes</h4>
                  <p>{workout.notes}</p>
                </div>
              )}

              <div className="workout-time">
                Workout Date: {new Date(workout.workout_date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Plans; 