import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import MetricHistory from './MetricHistory';
import './ClientMetrics.css';
import { useClient } from '../../context/ClientContext';

const ClientMetrics = () => {
  const { selectedClientContext } = useClient();
  const today = new Date();
  const localDate = today.toLocaleDateString('en-CA');

  const [metrics, setMetrics] = useState({
    weight: '',
    body_fat: '',
    muscle_mass: '',
    bmi: '',
    chest: '',
    waist: '',
    hips: '',
    thighs: '',
    biceps: '',
    date: localDate,
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    if (selectedClientContext) {
      setSelectedClient(selectedClientContext);
      setSearchQuery(`${selectedClientContext.first_name} ${selectedClientContext.last_name}`);
    }
    
    return () => {
      const today = new Date();
      const currentLocalDate = today.toLocaleDateString('en-CA');
      
      setSelectedClient(null);
      setSearchQuery('');
      setSearchResults([]);
      setMetrics({
        weight: '',
        body_fat: '',
        muscle_mass: '',
        bmi: '',
        chest: '',
        waist: '',
        hips: '',
        thighs: '',
        biceps: '',
        date: currentLocalDate,
      });
    };
  }, [selectedClientContext]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== 'date' && value && !isNaN(value) && parseFloat(value) < 0) {
      return;
    }
    setMetrics(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) {
      setSearchResults([]);
      return;
    }
    try {
      // Split the search query into words
      const searchTerms = searchQuery.trim().split(/\s+/);
      
      let query = supabase
        .from('clients')
        .select('*');

      if (searchTerms.length === 1) {
        // If single word, search in both first and last name
        query = query.or(`first_name.ilike.%${searchTerms[0]}%,last_name.ilike.%${searchTerms[0]}%`);
      } else if (searchTerms.length > 1) {
        // If multiple words, assume first word is first name and last word is last name
        const firstName = searchTerms[0];
        const lastName = searchTerms[searchTerms.length - 1];
        query = query
          .ilike('first_name', `%${firstName}%`)
          .ilike('last_name', `%${lastName}%`);
      }

      const { data, error } = await query;

      if (error) throw error;
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching clients:', error);
      alert('Error searching clients: ' + error.message);
    }
  };

  const handleSelectClient = (client) => {
    setSelectedClient(client);
    setSearchResults([]);
    setSearchQuery(`${client.first_name} ${client.last_name}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedClient) {
      alert('Please select a client first');
      return;
    }

    const metricData = {
      client_id: selectedClient.id,
      weight: parseFloat(metrics.weight) || null,
      body_fat: parseFloat(metrics.body_fat) || null,
      muscle_mass: parseFloat(metrics.muscle_mass) || null,
      bmi: parseFloat(metrics.bmi) || null,
      chest: parseFloat(metrics.chest) || null,
      waist: parseFloat(metrics.waist) || null,
      hips: parseFloat(metrics.hips) || null,
      thighs: parseFloat(metrics.thighs) || null,
      biceps: parseFloat(metrics.biceps) || null,
      date: metrics.date
    };

    try {
      const { error } = await supabase
        .from('client_metrics')
        .insert([metricData]);

      if (error) throw error;

      setMetrics({
        weight: '',
        body_fat: '',
        muscle_mass: '',
        bmi: '',
        chest: '',
        waist: '',
        hips: '',
        thighs: '',
        biceps: '',
        date: localDate,
      });
      
      alert('Measurements saved successfully!');
      window.location.reload();

    } catch (error) {
      console.error('Error details:', error);
      alert('Error saving measurements: ' + error.message);
    }
  };

  const handleReset = () => {
    setMetrics({
      weight: '',
      body_fat: '',
      muscle_mass: '',
      bmi: '',
      chest: '',
      waist: '',
      hips: '',
      thighs: '',
      biceps: '',
      date: localDate,
    });
    setSelectedClient(null);
    setSearchQuery('');
    setSearchResults([]);
  };

  return (
    <div className="metrics-container">
      <h2>Client Measurements</h2>

      <section className="form-section search-section">
        <h3>Search Client</h3>
        <div className="search-container">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by name..."
            className="search-input"
          />
          <button onClick={handleSearch} className="search-btn">Search</button>
          <button onClick={handleReset} className="new-client-btn">Reset</button>
        </div>

        {searchResults.length > 0 && (
          <div className="search-results">
            {searchResults.map((client) => (
              <div 
                key={client.id} 
                className="client-result"
                onClick={() => handleSelectClient(client)}
              >
                <span>{client.first_name} {client.last_name}</span>
                <span>{client.email}</span>
              </div>
            ))}
          </div>
        )}
      </section>

      {selectedClient && (
        <>
          <form onSubmit={handleSubmit} className="metrics-form">
            <div className="metrics-grid">
              <div className="metric-item">
                <label htmlFor="weight">Weight (lbs)</label>
                <input
                  type="number"
                  id="weight"
                  name="weight"
                  value={metrics.weight}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="body_fat">Body Fat %</label>
                <input
                  type="number"
                  id="body_fat"
                  name="body_fat"
                  value={metrics.body_fat}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="muscle_mass">Muscle Mass (lbs)</label>
                <input
                  type="number"
                  id="muscle_mass"
                  name="muscle_mass"
                  value={metrics.muscle_mass}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="bmi">BMI</label>
                <input
                  type="number"
                  id="bmi"
                  name="bmi"
                  value={metrics.bmi}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="chest">Chest (in)</label>
                <input
                  type="number"
                  id="chest"
                  name="chest"
                  value={metrics.chest}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="waist">Waist (in)</label>
                <input
                  type="number"
                  id="waist"
                  name="waist"
                  value={metrics.waist}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="hips">Hips (in)</label>
                <input
                  type="number"
                  id="hips"
                  name="hips"
                  value={metrics.hips}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="thighs">Thighs (in)</label>
                <input
                  type="number"
                  id="thighs"
                  name="thighs"
                  value={metrics.thighs}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="biceps">Biceps (in)</label>
                <input
                  type="number"
                  id="biceps"
                  name="biceps"
                  value={metrics.biceps}
                  onChange={handleChange}
                  step="0.1"
                />
              </div>

              <div className="metric-item">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={metrics.date}
                  onChange={handleChange}
                />
              </div>
            </div>

            <button type="submit" className="submit-btn">Save Measurements</button>
          </form>

          <MetricHistory clientId={selectedClient.id} />
        </>
      )}
    </div>
  );
};

export default ClientMetrics;

