import React, { useState, useEffect, useCallback } from 'react';
// Remove lines 2, 5-7
import { supabase } from '../../supabaseClient';
import './Exercises.css';


const muscleGroups = [
  'LEGS',
  'BACK',
  'CHEST',
  'SHOULDERS',
  'BICEP',
  'TRICEPS',
  'CORE',
  'CARDIO',
  'MULTI'
];

const Exercises = () => {
  const [exercises, setExercises] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedMuscleGroup, setSelectedMuscleGroup] = useState('');
  const [newExercise, setNewExercise] = useState({
    name: '',
    muscle_group: ''
  });
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingExercise, setEditingExercise] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch exercises only when muscle group is selected
  const fetchExercises = useCallback(async (muscleGroup) => {
    if (!muscleGroup) {
      setExercises([]);
      return;
    }

    setLoading(true);
    
    try {
      const { data, error } = await supabase
        .from('exercises')
        .select('*')
        .eq('muscle_group', muscleGroup)
        .order('name', { ascending: true });
      
      if (error) throw error;
      setExercises(data || []);
    } catch (error) {
      console.error('Error fetching exercises:', error);
      setExercises([]);
    } finally {
      setLoading(false);
    }
  }, []);

  // Get current user
  const getCurrentUser = useCallback(async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  }, []);

  // Initial load - only get user
  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  // Fetch exercises when muscle group changes
  useEffect(() => {
    fetchExercises(selectedMuscleGroup);
  }, [selectedMuscleGroup, fetchExercises]);

  const handleFilter = (muscleGroup) => {
    setSelectedMuscleGroup(muscleGroup);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newExercise.name || !newExercise.muscle_group) {
      alert('Please fill in all fields');
      return;
    }

    const { error } = await supabase
      .from('exercises')
      .insert([{
        name: newExercise.name,
        muscle_group: newExercise.muscle_group,
        created_by: user.id
      }]);

    if (error) {
      alert('Error adding exercise: ' + error.message);
    } else {
      setNewExercise({ name: '', muscle_group: '' });
      setShowAddForm(false);
      fetchExercises(selectedMuscleGroup);
      alert('Exercise added successfully!');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this exercise?')) {
      const { error } = await supabase
        .from('exercises')
        .delete()
        .eq('id', id);

      if (error) {
        alert('Error deleting exercise: ' + error.message);
      } else {
        fetchExercises(selectedMuscleGroup);
        alert('Exercise deleted successfully!');
      }
    }
  };

  const handleEdit = async (exercise) => {
    if (editingExercise && editingExercise.id === exercise.id) {
      const { error } = await supabase
        .from('exercises')
        .update({ 
          name: editingExercise.name,
          muscle_group: editingExercise.muscle_group 
        })
        .eq('id', exercise.id);

      if (error) {
        alert('Error updating exercise: ' + error.message);
      } else {
        setEditingExercise(null);
        fetchExercises(selectedMuscleGroup);
        alert('Exercise updated successfully!');
      }
    } else {
      setEditingExercise({
        id: exercise.id,
        name: exercise.name,
        muscle_group: exercise.muscle_group
      });
    }
  };

  const filteredExercises = exercises.filter(exercise =>
    exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="exercises-container">
      <button 
        className="toggle-form-btn"
        onClick={() => setShowAddForm(!showAddForm)}
      >
        {showAddForm ? 'Hide Form' : 'Add New Exercise'}
      </button>

      {showAddForm && (
        <div className="add-exercise-form">
          <h2>Add New Exercise</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Exercise Name"
                value={newExercise.name}
                onChange={(e) => setNewExercise({ ...newExercise, name: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <select
                value={newExercise.muscle_group}
                onChange={(e) => setNewExercise({ ...newExercise, muscle_group: e.target.value })}
                required
              >
                <option value="">Select Muscle Group</option>
                {muscleGroups.map(group => (
                  <option key={group} value={group}>{group}</option>
                ))}
              </select>
            </div>
            <div className="form-actions">
              <button type="submit" className="add-btn">Add</button>
              <button 
                type="button" 
                className="cancel-btn"
                onClick={() => setShowAddForm(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="exercises-list">
        <div className="exercises-header">
          <h2>Exercise List {selectedMuscleGroup && `- ${selectedMuscleGroup}`}</h2>
          <div className="filter-controls">
            <input
              type="text"
              placeholder="Search exercises..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <select
              value={selectedMuscleGroup}
              onChange={(e) => handleFilter(e.target.value)}
              className="filter-select"
            >
              <option value="">Select Muscle Group</option>
              {muscleGroups.map(group => (
                <option key={group} value={group}>{group}</option>
              ))}
            </select>
          </div>
        </div>

        {loading ? (
          <div>Loading...</div>
        ) : selectedMuscleGroup ? (
          <div className="exercises-table">
            <div className="table-header">
              <div className="col-name">Exercise Name</div>
              <div className="col-group">Muscle Group</div>
              <div className="col-actions">Actions</div>
            </div>
            
            {filteredExercises.map((exercise) => (
              <div key={exercise.id} className="table-row">
                <div className="col-name">
                  {editingExercise && editingExercise.id === exercise.id ? (
                    <input
                      type="text"
                      value={editingExercise.name}
                      onChange={(e) => setEditingExercise({
                        ...editingExercise,
                        name: e.target.value
                      })}
                      autoFocus
                    />
                  ) : (
                    exercise.name
                  )}
                </div>
                <div className="col-group">
                  {editingExercise && editingExercise.id === exercise.id ? (
                    <select
                      value={editingExercise.muscle_group}
                      onChange={(e) => setEditingExercise({
                        ...editingExercise,
                        muscle_group: e.target.value
                      })}
                    >
                      {muscleGroups.map(group => (
                        <option key={group} value={group}>{group}</option>
                      ))}
                    </select>
                  ) : (
                    exercise.muscle_group
                  )}
                </div>
                <div className="col-actions">
                  <button 
                    className={editingExercise && editingExercise.id === exercise.id ? "save-button" : "edit-button"}
                    onClick={() => handleEdit(exercise)}
                  >
                    {editingExercise && editingExercise.id === exercise.id ? 'Save' : 'Edit'}
                  </button>
                  <button 
                    className="delete-button"
                    onClick={() => handleDelete(exercise.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>Select a muscle group to view exercises</div>
        )}
      </div>
    </div>
  );
};

export default Exercises; 