import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import './PreviousWorkouts.css';

const PreviousWorkouts = ({ selectedClient }) => {
  const [recentWorkouts, setRecentWorkouts] = useState([]);
  const [isHistoryView, setIsHistoryView] = useState(false);

  useEffect(() => {
    const fetchWorkouts = async () => {
      if (!selectedClient) return;

      const { data: workoutsData, error } = await supabase
        .from('workouts')
        .select(`
          *,
          workout_exercises (
            sets,
            reps,
            time,
            weight,
            exercises (
              name,
              muscle_group
            )
          )
        `)
        .eq('client_id', selectedClient.id)
        .eq('completed', true)
        .order('workout_date', { ascending: false })
        .limit(isHistoryView ? 50 : 3);

      if (error) {
        console.error('Error fetching workouts:', error);
        return;
      }

      // Get unique trainer IDs
      const trainerIds = [...new Set(workoutsData.map(w => w.trainer_id))];
      
      // Get trainer display names from profiles table
      const { data: profilesData } = await supabase
        .from('profiles')
        .select('id, display_name')
        .in('id', trainerIds);

      // Create a map of trainer IDs to display names
      const trainerNames = profilesData.reduce((acc, profile) => {
        acc[profile.id] = profile.display_name;
        return acc;
      }, {});

      // Add display names to workouts
      const workoutsWithTrainerNames = workoutsData.map(workout => ({
        ...workout,
        trainerName: trainerNames[workout.trainer_id] || 'Unknown'
      }));

      setRecentWorkouts(workoutsWithTrainerNames);
    };

    fetchWorkouts();
  }, [selectedClient, isHistoryView]);

  if (!selectedClient || recentWorkouts.length === 0) {
    return null;
  }

  return (
    <div className="previous-workouts">
      <div className="previous-workouts-header">
        <h3>Previous Workouts for {selectedClient.first_name} {selectedClient.last_name}</h3>
        <button 
          className="history-toggle-btn"
          onClick={() => setIsHistoryView(!isHistoryView)}
        >
          {isHistoryView ? 'Recent View' : 'History'}
        </button>
      </div>
      
      <div className={`workouts-container ${isHistoryView ? 'history-view' : ''}`}>
        {recentWorkouts.map((workout) => (
          <div key={workout.id} className="workout-card">
            <div className="workout-header">
              <h4>Date: {new Date(workout.workout_date).toLocaleDateString()}</h4>
              <span className="completion-date">
                Trainer: {workout.trainerName}
              </span>
            </div>

            <div className="exercises-list">
              <div className="exercise-header">
                <span className="exercise-name">Exercise</span>
                <span className="exercise-detail">Sets</span>
                <span className="exercise-detail">Reps</span>
                <span className="exercise-detail">Weight</span>
                <span className="exercise-detail">Time</span>
              </div>
              
              {workout.workout_exercises.map((exercise, index) => (
                <div key={index} className="exercise-row">
                  <span className="exercise-name">
                    {exercise.exercises.name}
                    <span className="muscle-group">({exercise.exercises.muscle_group})</span>
                  </span>
                  <span className="exercise-detail">{exercise.sets || '-'}</span>
                  <span className="exercise-detail">{exercise.reps || '-'}</span>
                  <span className="exercise-detail">{exercise.weight || '-'}</span>
                  <span className="exercise-detail">{exercise.time || '-'}</span>
                </div>
              ))}
            </div>

            {workout.notes && (
              <div className="workout-notes">
                <h5>Notes:</h5>
                <p>{workout.notes}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreviousWorkouts;
