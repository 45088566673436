import React, { useEffect, useState, useCallback } from 'react';
import { supabase } from '../../supabaseClient';
import './MetricHistory.css';

const MetricHistory = ({ clientId }) => {
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchMetrics = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('client_metrics')
        .select('*')
        .eq('client_id', clientId)
        .order('date', { ascending: false })
        .limit(10);

      if (error) throw error;
      setMetrics(data);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    } finally {
      setLoading(false);
    }
  }, [clientId]);

  useEffect(() => {
    if (clientId) {
      fetchMetrics();
    }
  }, [clientId, fetchMetrics]);

  if (loading) return <div>Loading history...</div>;
  if (!metrics.length) return <div>No previous measurements found.</div>;

  return (
    <div className="metric-history">
      <h3>Previous Measurements</h3>
      <div className="metric-history-table">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Weight (lbs)</th>
              <th>Body Fat %</th>
              <th>Muscle Mass (lbs)</th>
              <th>BMI</th>
              <th>Chest (in)</th>
              <th>Waist (in)</th>
              <th>Hips (in)</th>
              <th>Thighs (in)</th>
              <th>Biceps (in)</th>
            </tr>
          </thead>
          <tbody>
            {metrics.map((metric) => (
              <tr key={metric.id}>
                <td>{new Date(metric.date + 'T00:00:00').toLocaleDateString()}</td>
                <td>{metric.weight}</td>
                <td>{metric.body_fat}</td>
                <td>{metric.muscle_mass}</td>
                <td>{metric.bmi}</td>
                <td>{metric.chest}</td>
                <td>{metric.waist}</td>
                <td>{metric.hips}</td>
                <td>{metric.thighs}</td>
                <td>{metric.biceps}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MetricHistory; 