import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../context/AuthContext';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './Workout.css';
import PreviousWorkouts from './PreviousWorkouts';
import { useClient } from '../../context/ClientContext';


const Workout = () => {
  const { workoutClientContext, setWorkoutClientContext } = useClient();
  const { session } = useAuth();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(workoutClientContext);
  const [searchClient, setSearchClient] = useState(
    workoutClientContext ? `${workoutClientContext.first_name} ${workoutClientContext.last_name}` : ''
  );
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [workoutDate, setWorkoutDate] = useState(new Date());
  const [exercises, setExercises] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [workoutRows, setWorkoutRows] = useState([
    { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
    { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
    { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
  ]);
  const [activeRow, setActiveRow] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [workoutNotes, setWorkoutNotes] = useState('');
  const [showPRContainer, setShowPRContainer] = useState(false);
  const [prExercise, setPrExercise] = useState('');
  const [prWeight, setPrWeight] = useState('');
  const [prReps, setPrReps] = useState('');
  const [prSearchTerm, setPrSearchTerm] = useState('');
  const [filteredPrExercises, setFilteredPrExercises] = useState([]);
  const [clientPRs, setClientPRs] = useState([]);

  // Fetch clients
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const { data, error } = await supabase
          .from('clients')
          .select('*');
        if (error) throw error;
        setClients(data || []);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
    fetchClients();
  }, []);

  // Add this effect right after the clients fetch effect
  useEffect(() => {
    const initializeWorkout = async () => {
      if (workoutClientContext) {
        console.log('Initializing workout from context:', workoutClientContext);
        
        // Check for incomplete workout
        const { data: incompleteWorkout, error: workoutError } = await supabase
          .from('workouts')
          .select(`
            *,
            workout_exercises (
              id,
              exercise_id,
              sets,
              reps,
              time,
              weight,
              exercises (
                id,
                name,
                muscle_group
              )
            )
          `)
          .eq('client_id', workoutClientContext.id)
          .eq('completed', false)
          .order('workout_date', { ascending: false })
          .limit(1)
          .single();

        if (workoutError && workoutError.code !== 'PGRST116') {
          console.error('Error fetching incomplete workout:', workoutError);
          return;
        }

        if (incompleteWorkout) {
          setWorkoutDate(new Date(incompleteWorkout.workout_date));
          setWorkoutNotes(incompleteWorkout.notes || '');
          
          const exerciseRows = incompleteWorkout.workout_exercises.map(we => ({
            exerciseId: we.exercise_id,
            exerciseName: we.exercises.name,
            muscleGroup: we.exercises.muscle_group,
            sets: we.sets || '',
            reps: we.reps || '',
            time: we.time || '',
            weight: we.weight || ''
          }));

          while (exerciseRows.length < 3) {
            exerciseRows.push({ 
              exerciseId: '', 
              exerciseName: '', 
              muscleGroup: '', 
              sets: '', 
              reps: '', 
              time: '',
              weight: '' 
            });
          }

          setWorkoutRows(exerciseRows);
        }
      }
    };

    initializeWorkout();
  }, [workoutClientContext]); // Only depend on workoutClientContext

  const filteredClients = clients.filter(client => 
    `${client.first_name} ${client.last_name}`.toLowerCase().includes(searchClient.toLowerCase())
  );

  const handleClientSelect = useCallback(async (client) => {
    console.log('1. handleClientSelect called with client:', client);
    setSelectedClient(client);
    setWorkoutClientContext(client);
    setSearchClient(`${client.first_name} ${client.last_name}`);
    setShowClientDropdown(false);

    const fetchClientPRs = async (clientId) => {
      const { data, error } = await supabase
        .from('personal_records')
        .select(`
          *,
          exercises (
            name
          )
        `)
        .eq('client_id', clientId)
        .order('achieved_date', { ascending: false })
        .limit(3);

      if (!error && data) {
        const prs = data.map(pr => ({
          exerciseName: pr.exercises.name,
          weight: pr.weight,
          reps: pr.reps,
          date: new Date(pr.achieved_date).toLocaleDateString()
        }));
        setClientPRs(prs);
      }
    };

    await fetchClientPRs(client.id);

    // Check for incomplete workout
    console.log('2. Fetching incomplete workouts for client ID:', client.id);
    const { data: incompleteWorkout, error: workoutError } = await supabase
      .from('workouts')
      .select(`
        *,
        workout_exercises (
          id,
          exercise_id,
          sets,
          reps,
          time,
          weight,
          exercises (
            id,
            name,
            muscle_group
          )
        )
      `)
      .eq('client_id', client.id)
      .eq('completed', false)
      .order('workout_date', { ascending: false })
      .limit(1)
      .single();

    console.log('3. Incomplete workout query result:', { incompleteWorkout, workoutError });

    if (workoutError && workoutError.code !== 'PGRST116') {
      console.error('4. Error fetching incomplete workout:', workoutError);
      return;
    }

    if (incompleteWorkout) {
      console.log('5. Found incomplete workout, populating form:', incompleteWorkout);
      setWorkoutDate(new Date(incompleteWorkout.workout_date));
      setWorkoutNotes(incompleteWorkout.notes || '');
      
      const exerciseRows = incompleteWorkout.workout_exercises.map(we => ({
        exerciseId: we.exercise_id,
        exerciseName: we.exercises.name,
        muscleGroup: we.exercises.muscle_group,
        sets: we.sets || '',
        reps: we.reps || '',
        time: we.time || '',
        weight: we.weight || ''
      }));

      console.log('6. Mapped exercise rows:', exerciseRows);

      while (exerciseRows.length < 3) {
        exerciseRows.push({ 
          exerciseId: '', 
          exerciseName: '', 
          muscleGroup: '', 
          sets: '', 
          reps: '', 
          time: '',
          weight: '' 
        });
      }

      setWorkoutRows(exerciseRows);
    } else {
      console.log('7. No incomplete workout found, resetting form');
      setWorkoutDate(new Date());
      setWorkoutRows([
        { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
        { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
        { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' }
      ]);
    }
  }, [setWorkoutClientContext, setWorkoutDate, setWorkoutNotes, setWorkoutRows, setClientPRs]);

  // Update the initialization effect with proper dependencies
  useEffect(() => {
    const initializeClientWorkout = async () => {
      if (workoutClientContext && !selectedClient) {
        console.log('Initializing workout from context on mount');
        await handleClientSelect(workoutClientContext);
      }
    };
    
    initializeClientWorkout();
  }, [workoutClientContext, selectedClient, handleClientSelect]);

  // Fetch exercises from Supabase
  useEffect(() => {
    const fetchExercises = async () => {
      const { data, error } = await supabase
        .from('exercises')
        .select('*');
      if (!error) {
        setExercises(data);
      }
    };
    fetchExercises();
  }, []);

  // Filter exercises based on search term
  useEffect(() => {
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      const filtered = exercises.filter(exercise =>
        exercise.name.toLowerCase().includes(searchLower) ||
        exercise.muscle_group.toLowerCase().includes(searchLower)
      );
      setFilteredExercises(filtered);
    } else {
      setFilteredExercises([]);
    }
  }, [searchTerm, exercises]);

  // Filter PR exercises based on search term
  useEffect(() => {
    if (prSearchTerm) {
      const filtered = exercises.filter(exercise =>
        exercise.name.toLowerCase().includes(prSearchTerm.toLowerCase())
      );
      setFilteredPrExercises(filtered);
    } else {
      setFilteredPrExercises([]);
    }
  }, [prSearchTerm, exercises]);

  const fetchLastExerciseData = async (clientId, exerciseId) => {
    const { data, error } = await supabase
      .from('workout_exercises')
      .select(`
        sets,
        reps,
        weight,
        time,
        workout_id,
        workouts!inner(
          client_id
        )
      `)
      .eq('workouts.client_id', clientId)
      .eq('exercise_id', exerciseId)
      .order('created_at', { ascending: false })
      .limit(1);

    if (error) {
      console.error('Error fetching last exercise data:', error);
      return null;
    }

    return data?.[0] || null;
  };

  const handleExerciseSelect = async (exercise, rowIndex) => {
    if (!selectedClient) return;

    // First update the basic exercise info
    const newRows = [...workoutRows];
    
    // Fetch last exercise data for this client and exercise
    const lastData = await fetchLastExerciseData(selectedClient.id, exercise.id);

    newRows[rowIndex] = {
      ...newRows[rowIndex],
      exerciseId: exercise.id,
      exerciseName: exercise.name,
      muscleGroup: exercise.muscle_group,
      // Only populate these fields if we have previous data
      sets: lastData?.sets || '',
      reps: lastData?.reps || '',
      weight: lastData?.weight || '',
      time: lastData?.time || ''
    };

    setWorkoutRows(newRows);
    setActiveRow(null);
    setSearchTerm('');
  };

  const handleInputChange = (rowIndex, field, value) => {
    const newRows = [...workoutRows];
    newRows[rowIndex] = {
      ...newRows[rowIndex],
      [field]: value,
    };
    setWorkoutRows(newRows);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!selectedClient || !session) {
        alert('Please select a client');
        return;
      }

      const validRows = workoutRows.filter(row => row.exerciseName.trim());
      if (validRows.length === 0) {
        alert('Please add at least one exercise');
        return;
      }

      // Check if we're working with an existing workout
      const { data: existingWorkout } = await supabase
        .from('workouts')
        .select('id')
        .eq('client_id', selectedClient.id)
        .eq('completed', false)
        .single();

      let workoutId;

      if (existingWorkout) {
        // Update existing workout
        const { error } = await supabase
          .from('workouts')
          .update({
            workout_date: workoutDate.toISOString(),
            notes: workoutNotes
          })
          .eq('id', existingWorkout.id)
          .select()
          .single();

        if (error) throw error;
        workoutId = existingWorkout.id;

        // Update exercises
        const { error: deleteError } = await supabase
          .from('workout_exercises')
          .delete()
          .eq('workout_id', workoutId);

        if (deleteError) throw deleteError;

        const { error: insertError } = await supabase
          .from('workout_exercises')
          .insert(validRows.map(row => ({
            workout_id: workoutId,
            exercise_id: row.exerciseId,
            sets: row.sets,
            reps: row.reps,
            time: row.time,
            weight: row.weight
          })));

        if (insertError) throw insertError;

      } else {
        // Create new workout
        const { data, error } = await supabase
          .rpc('handle_workout_submission', {
            p_client_id: selectedClient.id,
            p_trainer_id: session.user.id,
            p_workout_date: workoutDate.toISOString(),
            p_notes: workoutNotes,
            p_workout_exercises: validRows.map(row => ({
              exercise_id: row.exerciseId,
              sets: row.sets,
              reps: row.reps,
              time: row.time,
              weight: row.weight
            }))
          });

        if (error) throw error;
        workoutId = data;
      }

      alert('Success! Workout has been saved.');

    } catch (error) {
      console.error('Error:', error);
      alert('Error saving workout: ' + error.message);
    }
  };

  const addExerciseRow = () => {
    setWorkoutRows([
      ...workoutRows,
      { exerciseId: '', exerciseName: '', sets: '', reps: '', time: '', weight: '', muscleGroup: '' }
    ]);
  };

  const handleComplete = async () => {
    if (!selectedClient || !session) {
      alert('Please select a client');
      return;
    }

    const validRows = workoutRows.filter(row => row.exerciseName.trim());
    if (validRows.length === 0) {
      alert('Please add at least one exercise');
      return;
    }

    try {
      // Call stored procedure
      const { data: workoutId, error } = await supabase
        .rpc('complete_workout', {
          p_client_id: selectedClient.id,
          p_workout_date: workoutDate.toISOString(),
          p_notes: workoutNotes,
          p_workout_exercises: validRows.map(row => ({
            exercise_id: row.exerciseId,
            sets: row.sets,
            reps: row.reps,
            time: row.time,
            weight: row.weight
          }))
        });

      if (error) {
        console.error('Transaction error:', error);
        throw new Error(error.message || 'Failed to complete workout');
      }

      if (!workoutId) {
        throw new Error('No workout ID returned from server');
      }

      setIsCompleted(true);
      resetForm();
      alert('Workout completed successfully!');

    } catch (error) {
      console.error('Error completing workout:', error);
      alert('Error completing workout: ' + error.message);
    }
  };

  // Add a reset form function if needed
  const resetForm = () => {
    setSelectedClient(null);
    setSearchClient('');
    setWorkoutDate(new Date());
    setWorkoutRows([
      { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
      { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' },
      { exerciseId: '', exerciseName: '', muscleGroup: '', sets: '', reps: '', time: '', weight: '' }
    ]);
    setIsCompleted(false);
    setWorkoutNotes('');
  };

  // Add this new function to handle saving PRs
  const handleSavePR = async () => {
    if (!selectedClient || !prExercise || !prWeight || !prReps) {
      alert('Please fill in all PR fields');
      return;
    }

    try {
      const { error } = await supabase
        .from('personal_records')
        .insert({
          client_id: selectedClient.id,
          exercise_id: prExercise,
          weight: parseFloat(prWeight),
          reps: parseInt(prReps),
          verified_by: session.user.id,
          achieved_date: new Date().toISOString()
        });

      if (error) throw error;

      // Clear the PR form
      setPrExercise('');
      setPrWeight('');
      setPrReps('');
      setShowPRContainer(false);
      
      alert('PR saved successfully!');
    } catch (error) {
      console.error('Error saving PR:', error);
      alert('Error saving PR: ' + error.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="workout-form">
        <div className="workout-header">
          <div className="client-search">
            <input
              type="text"
              value={searchClient}
              onChange={(e) => {
                setSearchClient(e.target.value);
                setShowClientDropdown(true);
              }}
              placeholder="Search client..."
              className="client-search-input"
            />
            {showClientDropdown && searchClient && (
              <div className="client-dropdown">
                {filteredClients.map((client) => (
                  <div
                    key={client.id}
                    className="client-option"
                    onClick={() => handleClientSelect(client)}
                  >
                    {client.first_name} {client.last_name} - {client.email}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="date-picker-container">
            <DatePicker
              selected={workoutDate}
              onChange={(date) => setWorkoutDate(date)}
              className="date-picker"
            />
          </div>
        </div>

        {selectedClient && (
          <div className="client-info">
            <div className="client-details">
              <h3>Selected Client: {selectedClient.first_name} {selectedClient.last_name}</h3>
              <p>Goals: {selectedClient.goals || 'None'}</p>
              <p>Limitations: {selectedClient.injuries || 'None'}</p>
            </div>
            <div className="client-prs">
              <h3>Recent Personal Records</h3>
              <div className="pr-list">
                {clientPRs.map((pr, index) => (
                  <div key={index} className="pr-item">
                    <span className="pr-label">{pr.exerciseName}:</span>
                    <span className="pr-value">
                      {`${pr.weight}lbs × ${pr.reps} (${pr.date})`}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="workout-table">
          <div className="workout-row header">
            <div className="col exercise">Exercise Name</div>
            <div className="col sets">Sets</div>
            <div className="col reps">Reps</div>
            <div className="col weight">Weight</div>
            <div className="col time">Time</div>
            <div className="col muscle-group">Muscle Group</div>
          </div>

          {workoutRows.map((row, index) => (
            <div key={index} className="workout-row">
              <div className="col exercise">
                <input
                  type="text"
                  value={row.exerciseName}
                  onChange={(e) => {
                    handleInputChange(index, 'exerciseName', e.target.value);
                    setSearchTerm(e.target.value);
                    setActiveRow(index);
                  }}
                  onFocus={() => {
                    setActiveRow(index);
                    setSearchTerm(row.exerciseName);
                  }}
                  placeholder="Search by exercise or muscle group..."
                />
                {activeRow === index && searchTerm && (
                  <div className="exercise-dropdown">
                    {filteredExercises.map((exercise) => (
                      <div
                        key={exercise.id}
                        className="exercise-option"
                        onClick={() => handleExerciseSelect(exercise, index)}
                      >
                        {exercise.name} ({exercise.muscle_group})
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              <div className="col sets">
                <input
                  type="text"
                  value={row.sets}
                  onChange={(e) => handleInputChange(index, 'sets', e.target.value)}
                  placeholder="Sets"
                />
              </div>
              
              <div className="col reps">
                <input
                  type="text"
                  value={row.reps}
                  onChange={(e) => handleInputChange(index, 'reps', e.target.value)}
                  placeholder="Reps"
                />
              </div>
              
              <div className="col weight">
                <input
                  type="text"
                  value={row.weight}
                  onChange={(e) => handleInputChange(index, 'weight', e.target.value)}
                  placeholder="Weight"
                />
              </div>
              
              <div className="col time">
                <input
                  type="text"
                  value={row.time}
                  onChange={(e) => handleInputChange(index, 'time', e.target.value)}
                  placeholder="Time"
                />
              </div>

              <div className="col muscle-group">
                <div className="muscle-group-display">{row.muscleGroup}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="workout-notes">
          <h4>Workout Notes</h4>
          <textarea
            value={workoutNotes}
            onChange={(e) => setWorkoutNotes(e.target.value)}
            placeholder="Enter any additional notes about the workout..."
            rows={4}
            className="notes-textarea"
          />
        </div>

        <div className="form-actions">
          <button 
            type="button" 
            onClick={addExerciseRow} 
            className="add-exercise-btn"
          >
            + Add Exercise
          </button>
          <button 
            type="button"
            onClick={() => setShowPRContainer(!showPRContainer)}
            className="add-pr-btn"
          >
           + Add PR
          </button>
          <button 
            type="button"
            onClick={handleComplete}
            className="complete-btn"
            disabled={isCompleted || !selectedClient}
          >
            {isCompleted ? 'Completed' : 'Mark Complete'}
          </button>
          <button 
            type="submit" 
            className="workout-submit-btn"
            disabled={isCompleted}
          >
            Save Workout
          </button>
        </div>

        {showPRContainer && (
          <div className="pr-container">
            <h4>Personal Record</h4>
            <div className="pr-row">
              <div className="col exercise">
                <input
                  type="text"
                  value={prSearchTerm}
                  onChange={(e) => setPrSearchTerm(e.target.value)}
                  placeholder="Search exercise..."
                  className="pr-exercise-dropdown"
                />
                {prSearchTerm && (
                  <div className="exercise-dropdown">
                    {filteredPrExercises.map((exercise) => (
                      <div
                        key={exercise.id}
                        className="exercise-option"
                        onClick={() => {
                          setPrExercise(exercise.id);
                          setPrSearchTerm(`${exercise.name} (${exercise.muscle_group})`);
                        }}
                      >
                        {exercise.name} ({exercise.muscle_group})
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <input
                type="text"
                value={prWeight}
                onChange={(e) => setPrWeight(e.target.value)}
                placeholder="Weight"
                className="pr-weight-input"
              />
              <input
                type="text"
                value={prReps}
                onChange={(e) => setPrReps(e.target.value)}
                placeholder="Reps"
                className="pr-reps-input"
              />
              <button
                type="button"
                onClick={handleSavePR}
                className="workout-submit-btn"
              >
                Save PR
              </button>
            </div>
          </div>
        )}
      </form>
      
      <PreviousWorkouts selectedClient={selectedClient} />
    </>
  );
};

export default Workout; 