import React, { createContext, useContext, useState } from 'react';

const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [selectedClientContext, setSelectedClientContext] = useState(null);
  const [workoutClientContext, setWorkoutClientContext] = useState(null);

  return (
    <ClientContext.Provider value={{
      selectedClientContext,
      setSelectedClientContext,
      workoutClientContext,
      setWorkoutClientContext
    }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
}; 