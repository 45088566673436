import React, { useState, useEffect } from 'react';
import ClientInfo from './ClientInfo';
import ClientMetrics from './ClientMetrics';
import { useClient } from '../../context/ClientContext';
import './Client.css';

const Client = ({ clientId }) => {
  const [activeView, setActiveView] = useState('info'); // 'info' or 'metrics'
  const { setSelectedClientContext } = useClient();

  // Reset selected client when component unmounts or view changes
  useEffect(() => {
    if (activeView !== 'metrics') {
      setSelectedClientContext(null);
    }
  }, [activeView, setSelectedClientContext]);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      setSelectedClientContext(null);
    };
  }, [setSelectedClientContext]);

  return (
    <div className="client-container">
      <div className="view-toggle">
        <button
          className={`toggle-btn ${activeView === 'info' ? 'active' : ''}`}
          onClick={() => setActiveView('info')}
        >
          Client Info
        </button>
        <button
          className={`toggle-btn ${activeView === 'metrics' ? 'active' : ''}`}
          onClick={() => setActiveView('metrics')}
        >
          Measurements
        </button>
      </div>

      <div className="view-content">
        {activeView === 'info' ? (
          <ClientInfo clientId={clientId} />
        ) : (
          <ClientMetrics clientId={clientId} />
        )}
      </div>
    </div>
  );
};

export default Client;
