import { Link, useLocation, useNavigate } from 'react-router-dom';
// Remove lines 2, 5-8
import { supabase } from '../supabaseClient';
import { useEffect, useState } from 'react';
import './Nav.css';
import { useAuth } from '../context/AuthContext';

const Nav = () => {
  const { session } = useAuth();  // This is now being used
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        if (session?.user) {  // Use session instead of user
          const { data: adminData, error } = await supabase
            .from('user_roles')
            .select('role')
            .eq('user_id', session.user.id)  // Use session.user instead of user
            .single();
          
          if (error) throw error;
          setIsAdmin(adminData?.role === 'admin');
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, [session]);  // Depend on session instead of user

  // Close menu when location changes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  if (!session?.user) {
    return null;
  }

  return (
    <nav className="navbar">
      <button className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        <Link 
          to="/dashboard" 
          className={`nav-link ${isActive('/dashboard')}`}
          onClick={handleLinkClick}
        >
          Dashboard
        </Link>
        <Link 
          to="/workouts" 
          className={`nav-link ${isActive('/workouts')}`}
          onClick={handleLinkClick}
        >
          Workouts
        </Link>
        <Link 
          to="/plans" 
          className={`nav-link ${isActive('/plans')}`}
          onClick={handleLinkClick}
        >
          Plans
        </Link>
        <Link 
          to="/exercises" 
          className={`nav-link ${isActive('/exercises')}`}
          onClick={handleLinkClick}
        >
          Exercises
        </Link>
        <Link 
          to="/client-info" 
          className={`nav-link ${isActive('/client-info')}`}
          onClick={handleLinkClick}
        >
          Client Info
        </Link>
        <Link 
          to="/progress" 
          className={`nav-link ${isActive('/progress')}`}
          onClick={handleLinkClick}
        >
          Progress
        </Link>
        {isAdmin && (
          <Link 
            to="/admin" 
            className={`nav-link ${isActive('/admin')}`}
            onClick={handleLinkClick}
          >
            Admin Panel
          </Link>
        )}
        <button onClick={handleSignOut} className="logout-btn">
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Nav;
