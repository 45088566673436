import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import './Dashboard.css';
import { useAuth } from '../../context/AuthContext';
import { APP_VERSION } from '../../version';


const Dashboard = () => {
  const { session } = useAuth();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [weather, setWeather] = useState(null);
  const [weatherError, setWeatherError] = useState(null);
  const [workouts, setWorkouts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Update time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Fetch weather data
  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch(
          `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_API_KEY}&q=11773&aqi=no`
        );
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error.message || 'Failed to fetch weather data');
        }
        
        const data = await response.json();
        setWeather(data);
      } catch (error) {
        console.error('Error fetching weather:', error);
        setWeatherError(error.message);
      }
    };

    fetchWeather();
  }, []);

  // Fetch workouts and client data
  useEffect(() => {
    const fetchWorkouts = async () => {
      try {
        setIsLoading(true);
        setError(null);

        if (!session?.user) {
          throw new Error('No active session');
        }

        // Create proper date range using Date objects
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);

        console.log('Fetching workouts between:', startDate, 'and', endDate);

        const { data, error } = await supabase
          .from('workouts')
          .select(`
            *,
            clients (
              first_name,
              last_name
            )
          `)
          .eq('trainer_id', session.user.id)
          .gte('workout_date', startDate.toISOString())
          .lt('workout_date', endDate.toISOString())
          .order('workout_date');

        if (error) throw error;

        setWorkouts(data || []);

      } catch (error) {
        console.error('Error fetching workouts:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkouts();

    // Set up auto-refresh every minute
    const refreshInterval = setInterval(fetchWorkouts, 60000);
    return () => clearInterval(refreshInterval);
  }, [session]);

  if (isLoading) {
    return <div>Loading workouts...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <img 
          src="/G2Logo.jpg" 
          alt="G2 Logo" 
          className="g2-logo"
        />
        
        <div className="info-container">
          <div className="date-time">
            <div className="time2">
              {currentTime.toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
              })}
            </div>
            <div className="date">
              {currentTime.toLocaleDateString([], { 
                weekday: 'long', 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
              })}
            </div>
          </div>

          {weather ? (
            <div className="weather">
              <div className="temperature">
                {Math.round(weather.current.temp_f)}°F
              </div>
              <div className="weather-details">
                <div className="weather-description">
                  {weather.current.condition.text}
                  <img 
                    src={`https:${weather.current.condition.icon}`} 
                    alt={weather.current.condition.text}
                    className="weather-icon"
                  />
                </div>
                <div className="weather-stats">
                  <div>Feels like: {Math.round(weather.current.feelslike_f)}°F</div>
                  <div>Humidity: {weather.current.humidity}%</div>
                  <div>Wind: {weather.current.wind_mph} mph {weather.current.wind_dir}</div>
                  <div>UV Index: {weather.current.uv}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="weather-error">
              {weatherError || 'Loading weather...'}
            </div>
          )}
        </div>
      </div>

      <div className="workouts-summary">
        <h2>Today's Planned Workouts: {workouts.length}</h2>
        {workouts.length > 0 ? (
          <div className="workouts-list">
            {workouts.map((workout) => (
              <div key={workout.id} className="workout-item">
                <h3>
                  {workout.clients?.first_name} {workout.clients?.last_name}
                </h3>
                
                <p>Status: {workout.completed ? 'Completed' : 'Pending'}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>No workouts planned for today</p>
        )}
      </div>

      <div className="version-info" style={{
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        padding: '5px 10px',
        backgroundColor: '#f0f0f0',
        borderRadius: '4px',
        fontSize: '12px',
        opacity: '0.7'
      }}>
        Version: {APP_VERSION}
      </div>
    </div>
  );
};

export default Dashboard; 