import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AuthProvider, useAuth } from './context/AuthContext';
import { supabase } from './supabaseClient';
import Nav from './trainer/Nav';
import Login from './Login';
import Workout from './trainer/workouts/Workout';
import Plans from './trainer/plans/Plans';
import Exercises from './trainer/exercises/Exercises';
import Client from './trainer/client_info/Client';
import Dashboard from './trainer/dashboard/Dashboard';
import Progress from './trainer/progress/Progress';
import Admin from './admin/Admin';
import { APP_VERSION } from './version';
import { ClientProvider } from './context/ClientContext';

function App() {
  return (
    <ClientProvider>
      <VersionCheck>
        <Router>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </Router>
      </VersionCheck>
    </ClientProvider>
  );
}

function AppContent() {
  const { session, loading } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkVersionAndAdmin = async () => {
      try {
        // Version check
        const lastVersion = localStorage.getItem('appVersion');
        if (lastVersion !== APP_VERSION) {
          await supabase.auth.signOut();
          localStorage.clear();
          localStorage.setItem('appVersion', APP_VERSION);
          window.location.reload();
          return;
        }

        // Admin check
        if (session?.user) {
          const { data: adminData } = await supabase
            .from('user_roles')
            .select('role')
            .eq('user_id', session.user.id)
            .single();
          setIsAdmin(adminData?.role === 'admin');
        }
      } catch (error) {
        console.error('Check error:', error);
      }
    };

    checkVersionAndAdmin();
  }, [session]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      {session && <Nav />}
      <Routes>
        <Route 
          path="/login" 
          element={session ? <Navigate to="/dashboard" /> : <Login />} 
        />
        <Route 
          path="/dashboard" 
          element={session ? <Dashboard /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/exercises" 
          element={session ? <Exercises /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/workouts" 
          element={session ? <Workout /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/plans" 
          element={session ? <Plans /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/client-info" 
          element={session ? <Client /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/progress" 
          element={session ? <Progress /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin" 
          element={
            session ? (
              isAdmin ? <Admin /> : <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/login" />
            )
          } 
        />
        <Route 
          path="/" 
          element={session ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} 
        />
      </Routes>
    </div>
  );
}

function VersionCheck({ children }) {
  useEffect(() => {
    const checkVersion = () => {
      const lastVersion = localStorage.getItem('appVersion');
      if (lastVersion !== APP_VERSION) {
        console.log(`Version mismatch: ${lastVersion} → ${APP_VERSION}`);
        localStorage.clear();
        localStorage.setItem('appVersion', APP_VERSION);
        window.location.replace(window.location.href);
        return;
      }
    };

    checkVersion();
    // Also check when tab becomes visible again
    document.addEventListener('visibilitychange', checkVersion);
    return () => document.removeEventListener('visibilitychange', checkVersion);
  }, []);

  return children;
}

export default App;
